.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn, .ant-tabs-tab:hover {
  color: var(--primary) !important;
  font-weight: 500;
}

.ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active
{
  color: var(--anchor-primary-hover) !important;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: var(--border-primary);
  pointer-events: none;
}
