button, button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

.btn-fixed {
  position: fixed;
  right: 20px;
  bottom: 20px;

  button {
    width: 50px !important;
    height: 50px !important;
  }

  .btn {
    box-shadow: rgba(0,0,0,.3) 2px 5px 8px;
    user-select: none;
    border: 1px solid transparent;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &.rounded-circle {
      flex-wrap: wrap;
      justify-content: space-around;
      width: 55px;
      height: 55px;
      padding: 0;
      margin: 0;
      padding: 8px 14px;
      border-radius: 50% !important;
      line-height: 1.33;
  
      svg {
        font-size: 18px;
      }
    }
  }
}

.btn-danger {
  color: #fff;
  background-color: #f44336 !important;
  border-color: #dc3545;
}
