@import '_variables.scss';

:root {
  -primary: #17b0ec;
  --secondary: #1ebea5;
  --background: #07213a;
  --third: #dcdcdc;
  --pink: #ff4081;
  --danger: #f44336;

  --border-primary: #17b0ec;
  --border-secondary: #dcdcdc;

  --primary-text: #fff;
  --secondary-text: #17b0ec;
  --third-text: #6c757d;

  --header-background: #103447;
  --header-background-secondary: #07213a;

  --body-background: #F5F5F5;

  --modal-header-background: #f0f2f5;

  --menu-primary: #332b38;

  --input-text: #F5F5F5;
  --input-text-placeholder: #a3a3a3;
  --input-background: #103447;
  --input-border: #141414;

  --button-primary: #1273aa;
  --button-primary-hover: rgb(37, 187, 247);
  --button-primary-text: #F5F5F5;
  --button-primary-text-hover: #fff;
  --button-primary-border: #17b0ec;
  --button-primary-border-hover: #17b0ec;

  --anchor-primary: #d5ccd1;
  --anchor-primary-hover: rgb(37, 187, 247);

  --card-background: rgba(16, 52, 71, 0.05);
  --card-header-background: #f0f2f5;

  --project-item-title: #07213a;
  --project-icon: #8f8f8f;
  --project-icon-project: #52c41a;
  --project-icon-backlog: #1273aa;
  --project-icon-repository: #f5222d;
  --project-icon-epic: #fa8c16;
  --project-icon-feature: #722ed1;
  --project-icon-sprint: #fadb14;
}

.input-text-right {
  input {
    text-align: right;
  }

  &.ant-input-number {
    input {
      padding-right: 8px;
    }
  }
}